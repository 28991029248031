import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError, debounceTime, tap} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {LoadingService, LoadingStates} from "./loading.service";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private BackendURL = environment.backendURL;
  private BackendTelegramURL = environment.backendTelegramURL;
  private BackendAuthURL = environment.backendAuthURL;

  loadingService = inject(LoadingService)

  headers = new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
  });

  constructor(private readonly http: HttpClient) {}

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  RequestHandler(
    runRequest: () => Observable<any>,
    RequestType = LoadingStates.UNKNOWN
  ): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);
    return runRequest().pipe(
      debounceTime(50),
      tap(
        (dataRes) => {
          //console.log(`${loadingId} : DataRes = `, dataRes)
          this.loadingService.removeLoading(loadingId);
        },
        (catchError) => {
          this.loadingService.removeLoading(loadingId);
        }
      )
    );
  }

  get(path: string, params: HttpParams = new HttpParams(), token?: string,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {
    return this.RequestHandler(() => {
      return this.http
        .get(this.BackendURL + path, { params, headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);
  }
  getTelegram(path: string, params: HttpParams = new HttpParams(), token?: string,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {
    return this.RequestHandler(() => {
      return this.http
        .get(this.BackendTelegramURL + path, { params, headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);
  }
  put(path: string, body: any,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {
    return this.RequestHandler(() => {
      return this.http
        .put(this.BackendURL + path, body, { headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);

  }

  patch(path: string, body: any,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {

    return this.RequestHandler(() => {
      return this.http
        .patch(this.BackendURL + path, body, { headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);
  }

  post(path: string, body: any, token?: string,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {
    return this.RequestHandler(() => {
      return this.http
        .post(this.BackendURL + path, body, { headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);

  }

  postTelegram(path: string, body: any, token?: string,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {
    return this.RequestHandler(() => {
      return this.http
        .post(this.BackendTelegramURL + path, body, { headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);

  }

  SendDownloadGetCustom(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(method, {
        headers: new HttpHeaders(),
        responseType: "blob" as "json",
        observe: "response",
      });
    }, type);
  }




  postAuth(path: string, body: any, token?: string,type = this.loadingService.StatesNames.UNKNOWN): Observable<any> {

    return this.RequestHandler(() => {
      return this.http
        .post(this.BackendAuthURL + path, body, { headers: this.headers })
        .pipe(catchError(this.formatErrors));
    }, type);
  }




  getBackendUrl() {
    return `${this.BackendURL}`;
  }
}
